import React from 'react';

const MoreInformation = () => {
    const phone = require('../../../../assets/icons/phone-android.svg');
    const money = require('../../../../assets/icons/attach-money.svg');
    const arrow = require('../../../../assets/icons/right-arrow-orange.svg');
    return (
        <div className="more-info-container">
            <div className="more-info-container__content">
                <div className="d-flex pb-5 justify-content-between more-info-container__content_section">
                    <div className="d-flex align-items-start mt-3">
                        <img src={phone.default} alt="phone" />
                        <p className="more-info-text">
                            <b>We recommend calling ahead to confirm business hours and other details as information can change.</b>
                            <span>The City of Austin manages this resource.</span>
                        </p>
                    </div>
                    <div className="d-flex align-items-start mt-3">
                        <img src={money.default} alt="money" />
                        <p className="more-info-text">
                            <b>Austin residents throw away an estimated $11 million in reusable items yearly. </b>
                            <span>Reuse saves items from the landfill, gives them a new purpose, and can also save you money.</span>
                        </p>
                    </div>
                </div>
                <div className="link-container d-flex mb-2">
                    <p className="flex-grow-1">Submit an event for the calendar</p>
                    <a href='https://airtable.com/appm5EXI0KolNyzMS/shrdUlezSQc9fMDUt' target='_blank' rel="noreferrer"><img src={arrow.default} alt="right arrow" /></a>
                </div>
                <div className="link-container d-flex mb-2">
                    <p className="flex-grow-1">Fill out an application to list your business</p>
                    <a href='https://airtable.com/shralPX3aFG83I2Iz' target='_blank' rel="noreferrer"><img src={arrow.default} alt="right arrow" /></a>
                </div>
                <div className="link-container d-flex mb-2">
                    <p className="flex-grow-1">Update a listing or provide feedback by emailing us at circulareconomy@austintexas.gov </p>
                    <a href='mailto:circulareconomy@austintexas.gov'><img src={arrow.default} alt="right arrow" /></a>
                </div>
            </div>
        </div>
    );
}

export default MoreInformation;