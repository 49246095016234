import React, { useState, useMemo, useCallback } from "react"; // Added missing hooks
import PropTypes from "prop-types";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import { useSelector, useDispatch } from "react-redux";
import mapStyles from "./mapStyles";
import { setSelectedStoreDetails, toggleHighlightStore } from "../../../store/actions/austinReuseDirectory.actions";

// Static configuration moved outside component
const LIBRARIES = ["places"];
const MAP_CONTAINER_STYLE = {
  height: "100%",
  width: "100%",
};
const CENTER = {
  lat: 30.266666,
  lng: -97.73333,
};

const StoreMap = ({ show = false, onMarkerClick = () => {} }) => {
  const [markerIndex, setMarkerIndex] = useState(null);
  const [mapCenter, setMapCenter] = useState(CENTER);
  const { searchedStoreData } = useSelector((state) => state.arrReducer);
  const dispatch = useDispatch();
  const mapRef = React.useRef();

  // Memoized map options
  const mapOptions = useMemo(() => ({
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true,
    keyboardShortcuts: false,
  }), []);

  // Properly memoized load script configuration
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB-ypg_Rh3Dqq3oStewhC6xYLNq97mekm0',
    libraries: LIBRARIES, // Use static reference
  });

  // Memoized map load handler
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  // Memoized marker click handler
  const handleMarkerClick = useCallback((marker) => {
    onMarkerClick(marker);
    const parent = document.querySelector('.store-list-container > .list-container-wrapper > .list-container');
    const element = document.getElementById(`store-${marker.id}`);
    
    if (parent && element) {
      parent.scroll(0, element.offsetTop - parent.offsetTop);
      
      setTimeout(() => {
        if (document.body.clientWidth > 768) {
          const modal = document.getElementById(`modal-${marker.id}`);
          if (modal) modal.style.top = "0";
        }
      }, 1);
    }
  }, [onMarkerClick]);

  if (loadError) return <div>Error loading map</div>;
  if (!isLoaded) return <div>Loading map...</div>;

  return (
    <div className={`map-container h-100${show ? "" : " d-none"}`}>
      <GoogleMap
        id="map"
        mapContainerStyle={MAP_CONTAINER_STYLE}
        zoom={10}
        center={mapCenter}
        options={mapOptions}
        onLoad={onMapLoad}
        onClick={() => dispatch(setSelectedStoreDetails({}))}
      >
        {searchedStoreData?.map((marker, index) => {
          const { Latitude, Longitude, OrgName, FormattedAddress } = marker.fields;
          
          return (
            <Marker
              key={index}
              position={{ lat: Number(Latitude), lng: Number(Longitude) }}
              onClick={() => setMarkerIndex(index)}
              icon={{
                url: require("../../../../assets/icons/map-pin-orange.svg").default,
                scaledSize: new window.google.maps.Size(30, 30),
              }}
            >
              {markerIndex === index && (
                <InfoWindow
                  onCloseClick={() => {
                    setMarkerIndex(null);
                    dispatch(toggleHighlightStore(""));
                  }}
                >
                  <div>
                    <h6>{OrgName}</h6>
                    <div>{FormattedAddress}</div>
                    <button 
                      type="button" 
                      className="link-click"
                      onClick={() => handleMarkerClick(marker)}
                    >
                      Click for More Info
                    </button>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        })}
      </GoogleMap>
    </div>
  );
};

StoreMap.propTypes = {
  show: PropTypes.bool,
  onMarkerClick: PropTypes.func,
};

export default React.memo(StoreMap);